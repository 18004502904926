<template>
  <VueHighcharts
    v-if="chartDepartureCategoryUpdate && !refreshChart"
    type="chart"
    :redrawOnUpdate="true"
    :oneToOneUpdate="true"
    :animateOnUpdate="true"
    :options="chartBarDepartureCategoryOptions"
  />
</template>

<script setup>
import { computed, defineEmits, defineProps, nextTick, ref, watch } from "vue";
import VueHighcharts from "vue3-highcharts";
import { useStore } from "vuex";

const emit = defineEmits(["update:loading"]);

const props = defineProps({
  dateFrom: {
    type: String,
  },
  dateTo: {
    type: String,
  },
  departuresKey: {
    type: Array,
  },
  isAccumulative: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

const store = useStore();

const chartDepartureCategoryUpdate = ref(false);
const chartDepartureCategoryData = ref([]);
const refreshChart = ref(false);

const getChartColors = computed(() => store.getters.getChartColors);
const getDepartureCategoryReport = computed(
  () => store.getters.getDepartureCategoryWorkHourReport
);

const chartBarDepartureCategoryOptions = computed(() => {
  return {
    colors: getChartColors.value,
    chart: {
      type: "column",
    },
    title: {
      text: "HH por categoría",
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      min: 0,
      title: {
        text: "HH",
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} HH</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    series: chartDepartureCategoryData.value,
  };
});

const getShowMenu = computed(() => store.getters.getShowMenu);

watch(
  () => getShowMenu.value,
  () => {
    refreshChart.value = true;
    nextTick(() => {
      refreshChart.value = false;
    });
  }
);

watch(
  () => getDepartureCategoryReport.value,
  (currentValue) => {
    chartDepartureCategoryUpdate.value = false;
    let dataValue = [];
    chartDepartureCategoryData.value = [];
    if (currentValue && currentValue.length) {
      // value Operario
      let operatioTime = [];
      // value Ayudante
      let ayudanteTime = [];
      // value Oficial
      let oficialTime = [];
      // value Oficial
      let capatazTime = [];
      for (let value of currentValue) {
        switch (value.categoryName) {
          case "Operario":
            operatioTime.push({ y: value.hoursWorked, name: value.name });
            break;
          case "Ayudante":
            ayudanteTime.push({ y: value.hoursWorked, name: value.name });
            break;
          case "Oficial":
            oficialTime.push({ y: value.hoursWorked, name: value.name });
            break;
          case "Capataz":
            capatazTime.push({ y: value.hoursWorked, name: value.name });
            break;
        }
      }
      if (operatioTime.length > 0)
        dataValue.push({
          name: "Operario",
          data: operatioTime,
        });
      if (ayudanteTime.length > 0)
        dataValue.push({
          name: "Ayudante",
          data: ayudanteTime,
        });
      if (oficialTime.length > 0)
        dataValue.push({
          name: "Oficial",
          data: oficialTime,
        });
      if (capatazTime.length > 0)
        dataValue.push({
          name: "Capataz",
          data: capatazTime,
        });
      nextTick(() => {
        chartDepartureCategoryData.value = dataValue;
        chartDepartureCategoryUpdate.value = true;
      });
    }
  }
);

const loadData = async () => {
  try {
    emit("update:loading", true);

    const filter = {
      dateFrom: props.dateFrom,
      departuresKey: props.departuresKey || [],
    };
    if (props.dateTo) filter.dateTo = props.dateTo;

    await store.dispatch("loadDepartureCategoryWorkHourReport", filter);
  } catch (error) {
    console.error(error);
  } finally {
    emit("update:loading", false);
  }
};

watch(
  () => props.dateFrom,
  () => loadData()
);

watch(
  () => props.departuresKey,
  () => {
    if (props.isAccumulative) return;
    loadData();
  },
  { deep: true }
);

const init = async () => {
  await loadData();
};

init();
</script>
