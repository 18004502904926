<template>
  <div class="p-4 xl:w-full lg:w-screen pt-20">
    <div class="flex justify-end py-0 pr-5 pt-5">
      <button
        class="
          flex
          text-white
          border
          text-sm
          font-medium
          bg-primary
          border-primary
          py-2
          ml-4
          px-3
          my-auto
          justify-center
        "
        :disabled="loadingExportReport"
        @click="onExportReport"
      >
        <LoadingButtonIcon v-if="loadingExportReport" /> Exportar Reporte
      </button>

      <AcummulativeMenuOptions :date-from="dateFrom" :date-to="dateTo" />
    </div>
    <div class="lg:p-5 flex align-start p-5">
      <span class="my-auto">Hasta</span>
      <input
        v-model="dateTo"
        :min="dateFrom"
        readonly
        type="date"
        class="
          mx-5
          relative
          p-2
          bg-white
          rounded
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          focus:outline-none
          px-4
        "
      />

      <div class="lg:w-full">
        <Multiselect
          v-model="departuresKey"
          mode="tags"
          placeholder="Seleccione la(s) partida(s)"
          noOptionsText="No hay partidas"
          noResultsText="No hay resultados"
          :options="getDeparturesAndSubdepartures"
        />
      </div>

      <div class="ml-5">
        <button
          class="
            text-primary
            border
            text-sm
            font-medium
            border-primary
            p-2
            px-5
            h-full
          "
          @click="loadData()"
        >
          Filtrar
        </button>
      </div>
    </div>

    <div class="p-5 w-full">
      <h4 class="font-medium text-secondary text-xl">
        Número de trabajadores tareados por última vez:
        {{ countUserWorked }}
      </h4>
      <span class="text-sm">{{
        dateFromUserWorked
          ? ` Última fecha de tareo ${formatDate(dateFromUserWorked)}`
          : ""
      }}</span>
    </div>

    <div
      class="p-5 w-full"
      v-if="
        responseDepartureTotalHours &&
        responseDepartureTotalHours.length === 0 &&
        !loadingData
      "
    >
      <div class="h-max p-4 text-left text-secondary font-light bg-lightgray">
        <div class="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-secondary mx-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 8v4m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
            />
          </svg>
          Aún no se ha registrado información sobre la partida.
        </div>
      </div>
    </div>
    <div
      v-else
      v-show="
        loadingData ||
        loadingLineDepartureWorkHours ||
        loadingChartDepartureCategoryWorkHour ||
        loadingChartDepartureWorkHoursComparative ||
        loadingChartDepartureCategoryCost
      "
      class="flex justify-center"
    >
      <img :src="require('@/assets/loader.gif')" width="250" />
    </div>

    <div
      v-if="
        responseDepartureTotalHours && responseDepartureTotalHours.length !== 0
      "
      v-show="
        !loadingData &&
        !loadingLineDepartureWorkHours &&
        !loadingChartDepartureCategoryWorkHour &&
        !loadingChartDepartureWorkHoursComparative &&
        !loadingChartDepartureCategoryCost
      "
    >
      <TotalHoursCard
        :departuresKey="departuresKey"
        :responseDepartureTotalHours="responseDepartureTotalHours"
      />

      <div>
        <ChartLineDepartureWorkHours
          :date-from="dateFrom"
          :date-to="dateTo"
          :departures-key="departuresKey"
          :is-accumulative="true"
          :loading="loadingLineDepartureWorkHours"
          @update:loading="loadingLineDepartureWorkHours = $event"
        />
      </div>
      <div
        v-if="departuresKey.length === 1"
        v-show="!loadingLineDepartureWorkHoursAcumulative"
        class="mt-16"
      >
        <ChartLineDepartureWorkHoursAcumulative
          :date-from="dateFrom"
          :date-to="dateTo"
          :departures-key="departuresKey"
          :is-accumulative="true"
          :loading="loadingLineDepartureWorkHoursAcumulative"
          @update:loading="loadingLineDepartureWorkHoursAcumulative = $event"
        />
      </div>
      <!--
      <div
        v-if="departuresKey.length === 1"
        v-show="!loadingLineDepartureWorkHoursAcumulativeWeekly"
        class="mt-16"
      >
        <ChartLineDepartureWorkHoursAcumulativeWeekly
          :date-from="dateFrom"
          :date-to="dateTo"
          :departures-key="departuresKey"
          :loading="loadingLineDepartureWorkHoursAcumulativeWeekly"
          @update:loading="
            loadingLineDepartureWorkHoursAcumulativeWeekly = $event
          "
        />
      </div>
      -->

      <div class="mt-16">
        <ChartDepartureCategoryWorkHour
          :date-from="dateFrom"
          :date-to="dateTo"
          :departures-key="departuresKey"
          :is-accumulative="true"
          :loading="loadingChartDepartureCategoryWorkHour"
          @update:loading="loadingChartDepartureCategoryWorkHour = $event"
        />
      </div>

      <!-- 
      <div class="mt-16">
        <ChartDepartureWorkHours :date-from="dateFrom" :date-to="dateTo" />
      </div>
      -->

      <div class="mt-16">
        <ChartDepartureWorkHoursComparative
          :date-from="dateFrom"
          :date-to="dateTo"
          :departures-key="departuresKey"
          :is-accumulative="true"
          :loading="loadingChartDepartureWorkHoursComparative"
          @update:loading="loadingChartDepartureWorkHoursComparative = $event"
        />
      </div>

      <div class="mt-16">
        <ChartDepartureCategoryCost
          :date-from="dateFrom"
          :date-to="dateTo"
          :departures-key="departuresKey"
          :is-accumulative="true"
          :loading="loadingChartDepartureCategoryCost"
          @update:loading="loadingChartDepartureCategoryCost = $event"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Multiselect from "@vueform/multiselect";
import dayjs from "dayjs";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import ChartDepartureCategoryCost from "../components/ChartDepartureCategoryCost.vue";
import ChartDepartureCategoryWorkHour from "../components/ChartDepartureCategoryWorkHour.vue";
import ChartLineDepartureWorkHours from "../components/ChartLineDepartureWorkHours.vue";
//import ChartLineDepartureWorkHoursAcumulativeWeekly from "../components/ChartLineDepartureWorkHoursAcumulativeWeekly.vue";
import ChartLineDepartureWorkHoursAcumulative from "../components/ChartLineDepartureWorkHoursAcumulative.vue";
import ChartDepartureWorkHoursComparative from "../components/ChartDepartureWorkHoursComparative.vue";
import AcummulativeMenuOptions from "./components/AcummulativeMenuOptions.vue";
import TotalHoursCard from "./components/TotalHoursCard.vue";

const store = useStore();

const dateFrom = ref("2021-01-01");
const dateTo = ref("");
const departuresKey = ref([]);
const responseDepartureTotalHours = ref(null);
const loadingData = ref(true);
const loadingLineDepartureWorkHours = ref(true);
const loadingLineDepartureWorkHoursAcumulative = ref(false);
//const loadingLineDepartureWorkHoursAcumulativeWeekly = ref(true);
const loadingChartDepartureCategoryWorkHour = ref(true);
const loadingChartDepartureCategoryCost = ref(true);
const loadingChartDepartureWorkHoursComparative = ref(true);
const countUserWorked = ref(0);
const dateFromUserWorked = ref("");
const loadingExportReport = ref(false);

const getWork = computed(() => store.getters.getWork);
const getDepartures = computed(() => store.getters.getDepartures);
/*const getDeparturesList = computed(() =>
  getDepartures.value?.map((d) => ({ label: d.name, value: d.key }))
);*/

const getDeparturesAndSubdepartures = computed(() => {
  const departuresAndSubdepartures = getDepartures.value?.flatMap((d) => {
    const departures = [{ label: d.name, value: d.key }];
    if (d.subdepartures && d.subdepartures.length > 0) {
      const subdepartures = d.subdepartures.map((sub) => {
        if (
          responseDepartureTotalHours.value &&
          responseDepartureTotalHours.value.some(
            (r) => r.departureKey === sub.key
          )
        ) {
          responseDepartureTotalHours.value.find(
            (r) => r.departureKey === sub.key
          ).isSubdeparture = true;
        }
        return {
          label: sub.name,
          value: sub.key,
        };
      });

      return departures.concat(subdepartures);
    }
    return departures;
  });
  return departuresAndSubdepartures;
});

const loadData = async () => {
  try {
    loadingData.value = true;

    if (new Date(dateFrom.value).getTime() > new Date(dateTo.value).getTime())
      return;

    const response = await store.dispatch("loadUserWorkedReport", {
      dateFrom: dateFrom.value,
      isLastDate: true,
    });

    countUserWorked.value = response?.userWorked || 0;
    dateFromUserWorked.value = response?.dateFrom || "";

    const filter = {
      dateFrom: dateFrom.value,
      departuresKey: departuresKey.value || [],
    };
    if (dateTo.value) filter.dateTo = dateTo.value;

    responseDepartureTotalHours.value = null;

    responseDepartureTotalHours.value = await store.dispatch(
      "loadDepartureWorkHourReport",
      filter
    );
  } catch (error) {
    console.error(error);
  } finally {
    loadingData.value = false;
  }
};

watch(
  () => dateFrom.value,
  () => loadData()
);
watch(
  () => dateTo.value,
  () => loadData()
);

const init = () => {
  if (
    getWork.value &&
    [
      "c64fb811-f3f6-4160-8a34-9adb1ffdb3d6",
      "df213990-73b9-4a2e-82f5-6d37b1d891f5",
      "766cef75-bfb3-4a0b-8d68-543a11bbf401", // Sol de Pimentel
      "65281044-f259-4fff-ba5a-04b5c5f1ea07", // Sol de Chan Chan
      "8beafab5-93f7-4acd-bce5-7bba459dca0d", // Sol de Lambayeque
      "8ca68aae-1d7a-4fda-a45e-613aecd370e7", // Vista Alegre
    ].includes(getWork.value.key)
  )
    loadingChartDepartureCategoryCost.value = false;
  else loadingChartDepartureWorkHoursComparative.value = false;
  // dateFrom.value = getWeekInitialDate.value;
  dateTo.value = dayjs(new Date()).format("YYYY-MM-DD");
  // departuresKey.value = getDeparturesList.value?.length
  //   ? [getDeparturesList.value[0].value]
  //   : [];
};

init();

const formatDate = (dateString) => {
  return dayjs(dateString).format("DD/MM/YYYY");
};

const onExportReport = async () => {
  try {
    loadingExportReport.value = true;

    const reportUrl = await store.dispatch("exportTaskerAccumulative");

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", "tareo-acumulativo.pdf");
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingExportReport.value = false;
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect-tag {
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  position: relative;
  padding-right: 25px;
}

span.multiselect-tag-remove {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0px;
}

.multiselect-tags {
  max-height: 40px;
  min-height: 40px;
  overflow: auto;
  scroll-behavior: unset;
}
</style>
